import * as React from "react"
import { Helmet } from 'react-helmet'

import Header from '../shared/Header'
import Layout from '../shared/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet title="onedavila.com | 404" defer={false} />
      <Header />
    </Layout>
  )
}

export default NotFoundPage
